import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

type paymentStepsType = 'payment-information' | 'credit-card-information';

@Injectable({
  providedIn: 'root',
})
export class EasyCollectionDataBusService {
  paymentSteps$: Observable<paymentStepsType>;
  paymentStepsSubject: BehaviorSubject<paymentStepsType>;

  collectionData$: Observable<any>;
  collectionDataSubject: BehaviorSubject<any>;

  collectionOrderData$: Observable<any>;
  collectionOrderDataSubject: BehaviorSubject<any>;

  constructor() {
    this.paymentStepsSubject = new BehaviorSubject<paymentStepsType>(
      'payment-information'
    );
    this.paymentSteps$ = this.paymentStepsSubject.asObservable();

    this.collectionDataSubject = new BehaviorSubject<any>(null);
    this.collectionData$ = this.collectionDataSubject.asObservable();

    this.collectionOrderDataSubject = new BehaviorSubject<any>(null);
    this.collectionOrderData$ = this.collectionOrderDataSubject.asObservable();
  }

  changeActiveStep(
    newStep: 'payment-information' | 'credit-card-information'
  ): void {
    this.paymentStepsSubject.next(newStep);
  }

  updateCollectionData(data: any): void {
    this.collectionDataSubject.next(data);
  }

  updateCollectionOrderData(data: any): void {
    this.collectionOrderDataSubject.next(data);
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';
import { END_POINTS_URL } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root',
})
export class LevelService {
  constructor(private _http: HttpClient) {}

  getAllRates(channelId: string) {
    const URL = `${END_POINTS_URL.V2.LEVELS_URL}/all-rates`;
    const options = {
      headers: new HttpHeaders({
        'alias-Key': channelId,
      }),
    };
    return this._http
      .get(URL, options)
      .pipe(map((response: any) => response.data));
  }
}

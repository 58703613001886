import { HttpClient, HttpHeaders } from '@angular/common/http';

import { END_POINTS_URL } from 'src/app/shared/constants';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetInstallmentsModel } from '../models/installment.model';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private http: HttpClient) {}

  public getOrderInfoById(channelId: string, orderId: string): Observable<any> {
    const URL = `${END_POINTS_URL.V2.ORDERS_URL}/${orderId}`;
    const options = {
      headers: new HttpHeaders({
        'alias-Key': channelId,
      }),
    };
    return this.http.get(URL, options);
  }

  public getInstallmentList(payload: GetInstallmentsModel): Observable<any> {
    const URL = `${END_POINTS_URL.V2.LEVELS_URL}/rates?bin=${payload.bin}`;

    const options = {
      headers: new HttpHeaders({
        'alias-Key': payload.channelId,
      }),
    };
    return this.http.get(URL, options);
  }
}

// USA
export const locale = {
  lang: 'en',
  data: {
    RATES:"Rates",
    CONTACT_INFORMATION: 'Contact information',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    PHONE_NUMBER: 'Phone number',
    EMAIL: 'Email',
    SHIPPING_ADDRESS: 'Shipping address',
    ADDRESS: 'Address',
    HOUSE_NUMBER: ' Add a house number if you have one',
    CITY: 'City',
    REGION: 'Region',
    POSTAL_CODE: 'Postal code',
    CANCEL:"Cancel",
    CONTINUE:"Continue",
    CONTINUE_TO_PAYMENT: 'Continue to payment',
    OPEN_WEBSITE: 'Opens external website in a new window.',
    OPEN_WINDOW: 'Opens in a new window.',
    OPEN_EXTERNAL_WEBSITE: 'Opens external website.',
    CUSTOMER_INFORMATION: 'Customer information - Liva Test - Checkout',
    ORDER_SUMMERY: 'Order summary',
    SHIPPING_CART: 'Shopping cart',
    PRODUCT_IMAGE: 'Product image',
    DESCRIPTION: 'Description',
    QUANTITY: 'Quantity',
    PRICE: 'Price',
    SCROLL_FOR_MORE_ITEMS: 'Scroll for more items',
    COST_SUMMERY: ' Cost summary',
    TOTAL: 'Total',
    PROD_SERVICES_TOTAL: 'prod/services total',
    UPDATE_TOTAL_PRICE: 'Updated total price',
    APARTMENT_SUIT: 'Apartment, suite, etc. (optional)',
    COUNTRY_REGION: 'Country/region',
    CHANGE: 'Change',
    CONTACT: 'Contact',
    SHIP_TO: 'Ship to',
    PAYMENT: 'Payment',
    CHOOSE_PAYMENT_METHOD: 'Choose a payment method',
    COMPLETE_PAYMENT: 'Complete Payment',
    ALL_TRANSACTIONS_ARE_SECURE: 'All transactions are secure and encrypted.',
    FULFILLED_SUCCESSFULLY: 'Request fulfilled successfully',
    REQUEST_FAILED: 'Unfortunately request failed',
    ERROR: 'Error',
    SUCCESS: 'Success',
    MIN_LENGTH_ERROR: 'The field should have at least {{minlength}} symbols',
    MAX_LENGTH_ERROR: 'The field should have maximum of {{maxlength}} symbols',
    FIELD_REQUIRED: '{{field}} is required',
    FIELD_IS_NOT_VALID: '{{field}} is not valid',
    SKIP_TO_CONTENT: 'Skip to content',
    INFORMATION: 'Information',
    SALE_PRICE: 'Sale price',
    ALL_RIGHT_RESERVED: 'All rights reserved',
    CREDIT_CARD: 'Credit card',
    PAY_WITH: 'Pay with',
    CASH_ON_DELIVERY: 'Cash on Delivery (COD)',
    STORE: 'Store',
    SHOW_ORDER_SUMMARY: 'Show order summary',
    HIDE_ORDER_SUMMARY: 'Hide order summary',
    CARD_NUMBER: 'Card Number',
    NAME_ON_CARD: 'Name on card',
    EXPIRATION_DATE: 'Expiration date',
    EXPIRATION_DATE_WITH_INPUT_STYLE: 'Expiration date (MM / YY)',
    SECURITY_CODE: 'Security code',
    MONTH: 'Month',
    YEAR: 'Year',
    ERROR_404: 'OOPS! Something went wrong here',
    ITS_ORDERED: "It's Ordered!",
    THANKS_FOR_ORDER: 'Thanks, your payment has been received',
    SHOPPING_WITH_US: 'shopping with us',
    ORDER_NUMBER: 'Order Number',
    PAYMENT_FAILED: 'Payment failed',
    PAYMENT_FAILED_DESCRIPTION: 'Your payment method was decline',
    CODE: 'Code',
    INVALID_CVC: 'Your cvc is invalid',
    INVALID_CVC2: 'Your cvc2 is invalid',
    INSUFFICIENT_FUNDS: 'Your funds is not sufficient for the transaction',
    EXPIRED_CARD: 'Your card is expired',
    GENERAL_PAYMENT_ERROR: 'Sorry, your payment could not be completed',
    PAYMENT_NOT_COMPLETED:
      "Unfortunately, your payment operation didn't complete",
    YOUR_CARD_RESTRICTED:
      'Your card is restricted for online payments. You may enable online payments for  your card by contacting your bank',
    MAX_STOCK_EXCEEDED:
      "One of the items count in your basket is less than it's count in stock",
    PAYMENT_CONFIGURATION_NOT_SET:
      'Payment configuration not set in the seller panel',
    INVALID_TRANSACTION: 'Your transaction is invalid',
    OPERATION_NOT_APPROVED: 'Operation not approved',
    PAYMENT_REQUEST_HAS_NOT_BEEN_PASSED_FRAUD_CHECK:
      'Payment request has not passed Fraud check',
    OPERATION_NOT_PERMITTED_TO_CARD_HOLDER:
      'Operation not permitted to card holder',
    OPERATION_NOT_PERMITTED_TO_TERMINAL: 'Operation not permitted to terminal',
    FRAUD_SUSPECT: 'Fraud suspect',
    ERROR_PROCESSING_PAYMENT: 'An error occurred while processing payment',
    SYSTEM_ERROR: 'System error occurred',
    COMPLETE_PAYMENT_PROCESS: 'Complete payment process',
    FORM: 'Form',
    _3DS_SECURE_IS_NOT_SUPPORTED:
      "Can't connect payment service now or your credit card is not supported 3D secure",
    PROVIDER_IS_NOT_VALID: 'There is a problem with the payment provider',
    LOCAL_CARDS_ARE_INVALID:
      'Local cards are invalid for foreign currency payments',
    PHONE_IS_INVALID: 'Phone is invalid',
    WAIT_TILL_FORM_GET_LOADED:
      'Please wait until the bank card information is displayed ...',
    PAYMENT_OPTIONS: 'Payment Options',
    INSTALLMENT: 'Installment',
    INSTALLMENT_RATE: 'Installment Rate',
    TOTAL_AMOUNT:"Total Amount",
    ONE_SHOT:"One Shot",
    CLICK_FOR_ALL_CARDS:"Click for all cards",
    CHOOSE_INSTALLMENT:"Choose one of the below installments",
    TOTAL_PRICE: 'Total Price',
    INSTALLMENT_ERROR: 'Sorry, No installment can be made by your bank card',
    PAYMENT_REQUIRES_MORE_CREDIT:
      'This payment requires more credit, In order to complete this payment, you can use the installment option',
    PAYMENT_INFORMATION: 'Payment Information',
    PAYMENT_INFORMATION_DESCRIPTION: 'Amount and Customer Information',
    CREDIT_CARD_INFORMATION: 'Credit Card Information',
    CREDIT_CARD_INFORMATION_DESCRIPTION:
      'Credit Card and Installment Selection',
    PAYMENT_RESULT: 'Payment Result',
    PAYMENT_RESULT_DESCRIPTION: 'Transaction Result and Receipt',
    YOUR_NAME: 'Name and surname',
    E_MAIL: 'Email Address',
    GSM_NUMBER: 'GSM Number',
    AMOUNT: 'Amount',
    PAYMENT_OPTIONS_DESCRIPTION:
      'Enter the amount and credit card information.',
    NUMBER_OF_INSTALLMENTS: 'Number of Installments',
    INSTALLMENT_AMOUNT: 'Installment Amount',
    THE_TOTAL_PAYMENT: 'The total payment',
    INSTALLMENT_PAYMENT: 'Installment Payment',
    INSTALLMENTS: 'Installments',
    PAY: 'Pay',
    NEXT_STEP: 'Next Step',
    CVV_SECURITY_CODE: 'CVV Security Code',
    ENTER_SHIPPING_ADDRESS: 'Enter your shipping address details here',
    CHOOSE:"Choose",
    STORE_HAS_NO_ACTIVE_PAYMENT:"This store has no active payment method",
    LOWER_PRICE_ERROR:"Please lower your price, it exceeded from maximum amount"
  },
};

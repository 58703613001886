export const environment = {
  name: 'development',
  production: false,
  minLengthShortText: 3,
  maxLengthShortText: 10,
  minLengthLongText: 5,
  maxLengthLongText: 15,
  recaptchaSiteKey: 'RECAPTCHA',
  PUBLIC_API: 'https://dev.app-user.applyze.com',
  API_KEY: 'MbUoobH8kbSU1liJ9+PHq8aStx3/DmBxYGF74a8S/mI=',
  SHOPIROLLER_API: 'https://dev.ecommerce.shopiroller.com',
  version: 'v1.0.0.9-pre-dev'
};

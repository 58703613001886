import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { END_POINTS_URL } from 'src/app/shared/constants';
import { InsertOrderWithCollectionDto } from '../../modules/easy-collection/models/dtos/insert-order-with-collection.dto';
import { GetInstallmentRatesByCollectionDto } from '../../modules/easy-collection/models/dtos/get-installment-rates-by-collection-dto';

@Injectable({
  providedIn: 'root',
})
export class CollectionService {
  constructor(private _http: HttpClient) {}

  getBySlug(slug: string) {
    const URL = `${END_POINTS_URL.V2.COLLECTIONS_URL}/slugs/${slug}`;
    return this._http.get(URL).pipe(map((response: any) => response.data));
  }

  insert(channelId: string, payload: InsertOrderWithCollectionDto) {
    const URL = `${END_POINTS_URL.V2.ORDERS_URL}/collection`;
    const options = {
      headers: new HttpHeaders({
        'alias-Key': channelId,
      }),
    };
    return this._http.post(URL, payload, options);
  }

  getInstallmentRatesByCollection(payload: GetInstallmentRatesByCollectionDto) {
    const URL = `${END_POINTS_URL.V2.LEVELS_URL}/rates?bin=${payload.bin}`;
    const options = {
      headers: new HttpHeaders({
        'alias-Key': payload.channelId,
      }),
    };
    return this._http
      .get(URL, options)
      .pipe(map((response: any) => response.data));
  }
}
